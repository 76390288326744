.happy-customers-section {
    background: url("../../../public/svg/common/happy-customers/quote.svg")
        no-repeat top left;
        overflow: hidden;
    .happy-customers-block {
        h2 {
            max-width: 280px;
        }
        .lead {
            max-width: 440px;
        }
        small {
            color: #ff6737;
            font-size: 1.5rem;
        }
    }
    .happy-customers-slider {
        position: relative;
        height: 100svh;
        .avatar {
            img {
                width: 58px;
                height: 58px;
                border-radius: 50%;
                object-fit: cover;
                @media only screen and (max-width: 639px) {
                    width: 34px;
                    height: 34px;
                }
            }
        }
        span {
            font-size: 18px;
            @media only screen and (max-width: 639px) {
                font-size: 14px;
            }
        }
        .with-image,
        .without-image {
            border-radius: 14px;
            overflow: hidden;
        }
        .without-image {
            border: #e9dad4 1px solid;
            .lead {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
            }
        }
        &.happy-customers-slider-vertical {
            &:after,
            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                height: 130px;
                z-index: 2;
            }
            &:after {
                bottom: 0;
                background: linear-gradient(
                    0deg,
                    #fff -0.02%,
                    rgba(255, 255, 255, 0) 67.92%
                );
            }
            &:before {
                top: 0;
                background: linear-gradient(
                    180deg,
                    #fff -0.02%,
                    rgba(255, 255, 255, 0) 67.92%
                );
            }
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }
        &.happy-customers-slider-horizontal {
            height: 256px;
            display: none;
            &:after,
            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 80px;
                z-index: 2;
            }
            &:after {
                left: 0;
                background: linear-gradient(90deg, #fff -0.02%, rgba(255, 255, 255, 0) 67.92%)
            }
            &:before {
                right: 0;
                background: linear-gradient(270deg, #fff -0.02%,rgba(255, 255, 255, 0) 67.92%);
            }
            @media only screen and (max-width: 1023px) {
                display: block;
            }
            @media only screen and (max-width: 639px) {
                height: 180px;
            }
        }
    }
    @media only screen and (max-width: 1023px) {
        padding-top: 180px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 100px;
        background-size: 108px;
    }
}
