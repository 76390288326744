.tiny {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 2rem;
}
.swiper-container {
    width: 100%;
    height: 100% !important;
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 200%;
        height: 1px;
        position: absolute;
        left: -10%;
        top: 40%;
        animation: line-slide 60s forwards infinite linear;
        z-index: 1;
    }
    &.swiper--bottom {
        &:after {
            animation-direction: reverse;
        }
    }
}
.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative;
}
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    position: relative;
    overflow: hidden;
}

@keyframes line-slide {
    0% {
        background-position: -5% 0;
    }
    100% {
        background-position: 100% 0;
    }
}
